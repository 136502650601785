import { setHtml } from '@webapp/common/lib/ui';

import { data } from './data';
import { Slider } from './slider';

import css from './feedback.css';

export const Feedback: FC = () => (
    <div className={css.feedback}>
        <h2 className={css.title}>Отзывы</h2>
        <Slider>
            {data.map(({ alt, description, name, post, src }, index) => (
                <div className={css.content} key={index}>
                    <div className={css.logo}>
                        <img className={css.img} src={src} title={alt} />
                    </div>
                    <div className={css.info}>
                        <h2 className={css.name}>{name}</h2>
                        <h3 className={css.post} {...setHtml(post)} />
                        <section className={css.text}>{description}</section>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
);
