// eslint-disable-next-line no-restricted-imports
import React from 'react';

import { isBrowser, isDev } from './const';

if (isBrowser && isDev && false) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    console.debug('Applying whyDidYouRender https://github.com/welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackExtraHooks: [[require('react-redux'), 'useSelector']],
        include: [/^ConnectFunction/],
        trackAllPureComponents: true,
        trackHooks: true,
        logOwnerReasons: true,
        collapseGroups: true,
        hotReloadBufferMs: 1500
    });
}
