import cn from 'classnames';

import css from './sizer.css';

export const Sizer: FC<{
    width: number;
    height: number;
    className?: string;
    innerClassName?: string;
}> = ({ className, height, innerClassName, width }) => (
    <div className={cn(css.sizer, className)}>
        <svg viewBox={`0 0 ${width} ${height}`} />
        {innerClassName && <div className={innerClassName} />}
    </div>
);
