// eslint-disable-next-line
require('@webapp/common/lib/sentry').default('landing');
require('@webapp/common/lib/wdyr');

import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import type { CmsPage } from '@webapp/landing/src/resources/api';

import { Head } from 'components/head';
import { Mainpage } from 'components/mainpage';

export const HomePage: NextPage<CmsPage> = ({ browserTitle, descr, keywords }: CmsPage) => {
    const { asPath } = useRouter();
    return (
        <>
            <Head description={descr} keywords={keywords} title={browserTitle} url={asPath} />
            <Mainpage />
            <div id='tooltip' />
        </>
    );
};
