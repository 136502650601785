import css from './logos.css';

export const content = [
    {
        title: 'Авито',
        style: css.avito
    },
    {
        title: 'Альфа-страхование',
        style: css.alphaEnsurance
    },
    {
        title: 'Альфа-банк',
        style: css.alphaBank
    },
    {
        title: 'Андерсон',
        style: css.anderson
    },
    {
        title: 'Beeline',
        style: css.beeline
    },
    {
        title: 'СУ22',
        style: css.cy22
    },
    {
        title: 'Кредит ЕвропаБанк',
        style: css.kreditEvropaBank
    },
    {
        title: 'Линзмастер',
        style: css.linzmaster
    },
    {
        title: 'Mercedes Benz',
        style: css.mercedes
    },
    {
        title: 'Metro CC',
        style: css.metroCC
    },
    {
        title: 'МTC Банк',
        style: css.mtsBank
    },
    {
        title: 'Му-Му',
        style: css.mumu
    },
    {
        title: 'Банк Открытие',
        style: css.openBank
    },
    {
        title: 'OSTIN',
        style: css.ostin
    },
    {
        title: 'Puma',
        style: css.puma
    },
    {
        title: 'Rambler',
        style: css.rambler
    },
    {
        title: 'ИНТЕР РАО ЕЭС',
        style: css.raoEes
    },
    {
        title: 'Ренессанс Страхование',
        style: css.renessans
    },
    {
        title: 'ROSGONKI',
        style: css.rosgonki
    },
    {
        title: 'Роза Хутор',
        style: css.rozaHutor
    },
    {
        title: 'Skyeng',
        style: css.skyeng
    },
    {
        title: 'Swagelok',
        style: css.swagelok
    },
    {
        title: 'Ticketland.ru',
        style: css.ticketland
    },
    {
        title: 'VTB',
        style: css.vtb
    }
];
