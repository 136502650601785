import { CaptureConsole, Dedupe, ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { CI, SENTRY_HOST } from '@webapp/common/conf';
import { isProd } from '@webapp/common/lib/const';

const isCI = !!CI;

const knownErrors = [
    'ResizeObserver loop limit exceeded', // https://github.com/souporserious/react-measure/issues/104,
    "'destroy'", // https://github.com/ckeditor/ckeditor5-react/issues/241,
    'Non-Error exception captured with keys', // sentry got not Error object
    'Socket hung up', // local dev-server
    'status code 404'
];

const withKnownError = (msg: string): boolean => knownErrors.some((e) => msg.includes(e));

const init = (_module: string): void => {
    if (!isProd || !isCI) return;

    const dsn = `https://82b6f05462dd4820a62cb105d6ed34fd@${SENTRY_HOST}/4`;

    Sentry.init({
        dsn,
        autoSessionTracking: false,
        // TODO check server mode
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({ levels: ['error'] }),
            new Dedupe(),
            new ExtraErrorData({
                depth: 3
            })
        ],
        beforeSend(event, hint) {
            const { originalException }: any = hint;
            const msg = event.message || (originalException as Error).message;

            if (withKnownError(msg) /* || originalException === null || originalException?.isAxiosError */) {
                return null;
            }

            return event;
        },
        tracesSampleRate: 1.0
    });
};

// eslint-disable-next-line import/no-default-export
export default init;
