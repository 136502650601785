import cn from 'classnames';
import { useEffect, useState } from 'react';

import { AccountRoutes } from '@webapp/account/src/resources/routes';
import { Layout } from '@webapp/landing/src/components/layout';

import { Button } from 'components/ui/button';

import { About } from './sections/about';
import { Advantages } from './sections/advantages';
import { Connect } from './sections/connect';
import { Feedback } from './sections/feedback';
import { More } from './sections/more';
import { Opportunities } from './sections/opportunities';
import { Trust } from './sections/trust';

import css from './mainpage.css';

export const Mainpage: FC = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => setVisible(true), 100);
    }, []);

    return (
        <Layout className={cn(css.page, visible && css.visible)} headerClassName={css.header} page='main'>
            <Connect />
            <More />
            <About />
            <Opportunities />
            <Advantages />
            <Feedback />
            <Trust />
            <div className={css.btn}>
                <Button href={AccountRoutes.authRegister.link}>Подключиться</Button>
            </div>
        </Layout>
    );
};
