import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useWindowSize } from '@webapp/common/hooks/use-window-size';

import { content } from './content';

import css from './trust.css';

const Logo: FC<{
    className: string;
    title: string;
}> = ({ className, title }) => (
    <div className={css.item}>
        <div className={cn(css.logo, className)} title={title} />
    </div>
);

export const Trust: FC = () => {
    const coord = useRef({
        offset: 0,
        width: 0,
        delta: 1, // TODO calc speed,
        lastX: null
    });
    const boxList = useRef<HTMLDivElement>(null);
    const container = useRef<HTMLDivElement>(null);
    const [windowWidth] = useWindowSize();
    const animateRef = useRef(null);
    const animationActive = useRef(true);

    const logos = useMemo(() => {
        const logos = [];
        const perColumn = windowWidth < 450 ? 3 : 2;
        let i = 0;
        const length = content.length;

        while (i < length) {
            const col = [];
            for (let j = 0; j < perColumn; j++) {
                const c = content[i % length];
                col.push(<Logo className={c.style} key={c.title} title={c.title} />);
                i++;
            }
            logos.push(
                <div className={css.itemBox} key={i}>
                    {col}
                </div>
            );
        }

        return logos;
    }, [windowWidth]);

    const setDefaultPosition = useCallback((): void => {
        coord.current.width = boxList.current?.offsetWidth;
        coord.current.offset = 0;
    }, []);

    const animate = useCallback((): void => {
        if (animationActive.current) {
            coord.current.offset -= coord.current.delta;
            if (-coord.current.offset >= coord.current.width / 2) {
                setDefaultPosition();
            }
            boxList.current.style.transform = `translate3d(${coord.current.offset}px, 0, 0)`;
        }
        animateRef.current = requestAnimationFrame(animate);
    }, [setDefaultPosition]);

    const move = useCallback(({ clientX, touches }) => {
        const x = clientX || (touches && touches[0] && touches[0].screenX) || 0;
        const offset = coord.current.offset - (coord.current.lastX - x);
        if (offset <= 0) {
            coord.current.offset = offset;
            boxList.current.style.transform = `translate3d(${coord.current.offset}px, 0, 0)`;
        }
        coord.current.lastX = x;
    }, []);

    const startDrag = useCallback(
        ({ clientX, touches }) => {
            animationActive.current = false;
            coord.current.lastX = clientX || (touches && touches[0] && touches[0].screenX) || 0;
            boxList.current.addEventListener('mousemove', move);
            boxList.current.addEventListener('touchmove', move, { passive: false });
        },
        [move]
    );

    const stopDrag = useCallback(() => {
        animationActive.current = true;
        boxList.current.removeEventListener('mousemove', move);
        boxList.current.removeEventListener('touchmove', move);
    }, [move]);

    useEffect(() => {
        setDefaultPosition();
        animateRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animateRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);

    return (
        <div className={css.container}>
            <h2 className={css.title}>Нам доверяют</h2>
            <div className={css.companies} ref={container}>
                <div
                    className={css.list}
                    ref={boxList}
                    onMouseDown={startDrag}
                    onMouseLeave={stopDrag}
                    onMouseUp={stopDrag}
                    onTouchEnd={stopDrag}
                    onTouchStart={startDrag}
                >
                    {logos}
                    {logos}
                </div>
            </div>
        </div>
    );
};
