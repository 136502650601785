import cn from 'classnames';

import { Tooltip } from '@webapp/ui/lib/tooltip';

import css from './about.css';

const tooltipOffset: [number, number] = [0, 30];

export const About: FC = () => (
    <div className={css.about}>
        <div className={css.container}>
            <h2 className={css.title}>O нас</h2>
            <h3 className={css.text}>
                Предоставляем онлайн сервис
                <br />
                для создания опросов и&nbsp;обработки результатов
            </h3>
            <div className={css.list}>
                <div className={cn(css.item, css.users)}>
                    <article className={css.article}>
                        <h2 className={css.label}>99.95%</h2>
                        <h3 className={css.note}>
                            доступность
                            <br />
                            сервиса
                            <Tooltip offset={tooltipOffset} placement='top-end'>
                                <div>
                                    Процент бесперебойной работы сервиса в течение года.
                                    <br />
                                    Показатель <b>99.95%</b> сравним со стабильностью работы крупнейших мировых сайтов.
                                </div>
                            </Tooltip>
                        </h3>
                    </article>
                </div>
                <div className={cn(css.item, css.load)}>
                    <article className={css.article}>
                        <h2 className={css.label}>до 50 000</h2>
                        <h3 className={css.note}>
                            человек на
                            <br /> опросе онлайн
                        </h3>
                    </article>
                </div>
                <div className={cn(css.item, css.answers)}>
                    <article className={css.article}>
                        <h2 className={css.label}>до 1 млн</h2>
                        <h3 className={css.note}>
                            ответов на
                            <br />
                            каждый опрос
                        </h3>
                    </article>
                </div>
            </div>
        </div>
    </div>
);
