import { AccountRoutes as AccountRoutes } from '@webapp/account/src/resources/routes';

import { Button } from 'components/ui/button';

import css from './opportunities.css';

const OppoItem: FC<{
    title: string;
    href: string;
}> = ({ href, title }) => (
    <li className={css.item}>
        <h3>
            <a className={css.link} href={href}>
                {title}
            </a>
        </h3>
    </li>
);

export const Opportunities: FC = () => (
    <div className={css.opportunities}>
        <div className={css.container}>
            <h2 className={css.title}>Возможности</h2>
            <ul className={css.list}>
                <OppoItem href='/ru/capabilities-hr' title='Опросы' />
                <OppoItem href='/ru/capabilities-tests' title='Тесты' />
                <OppoItem href='/ru/capabilities-votes' title='Голосования' />
                <OppoItem href='/ru/capabilities-mailing' title='E-mail рассылка' />
                <OppoItem href='/ru/capabilities-api' title='Api' />
                <OppoItem href='/ru/templates' title='Шаблоны' />
            </ul>
            <div className={css.btn}>
                <Button href={AccountRoutes.authRegister.link}>Подключиться</Button>
            </div>
        </div>
    </div>
);
