import { useCallback, useEffect, useState } from 'react';

import { isBrowser } from '@webapp/common/lib/const';

// TODO clear cache via callback counting
const cache = Object.create(null);

export const useMediaQuery = (query = ''): boolean => {
    const mql = isBrowser ? cache[query] || (cache[query] = window.matchMedia(query)) : null;
    const [queryResult, setQueryResult] = useState(isBrowser ? cache[query].matches : false);

    const setResult = useCallback(({ matches }) => {
        setQueryResult(matches);
    }, []);

    useEffect(() => {
        // IOS 13 addListener
        mql && mql.addEventListener ? mql.addEventListener('change', setResult) : mql.addListener(setResult);
        return () => {
            mql && mql.removeEventListener
                ? mql.removeEventListener('change', setResult)
                : mql.removeListener(setResult);
        };
    }, [mql, setResult]);

    return queryResult;
};
