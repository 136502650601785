import cn from 'classnames';
import { Children, useCallback, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { Nav } from '@webapp/ui/lib/icons';

import css from './slider.css';

const hidden = { display: 'none' };

export const Slider: FC = ({ children }) => {
    const length = Children.count(children);
    const range = useMemo(() => Array.from({ length }, (x, i) => i), [length]);
    const [current, setCurrent] = useState(0);
    const prev = current - 1 < 0 ? length - 1 : current - 1;
    const next = current + 1 >= length ? 0 : current + 1;
    const list = useRef<HTMLDivElement>();
    const listNext = useRef<HTMLDivElement>();
    const listPrev = useRef<HTMLDivElement>();
    const timerRef = useRef(null);

    const goNext = useCallback(
        (delta): void => {
            delta = typeof delta === 'number' ? delta : 1;
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setCurrent((current) => {
                    current = current + delta;
                    current = current < length ? current : 0;
                    return current;
                });
                listNext.current.style.display = 'none';
                list.current.classList.remove(css.closeLeft);
                listNext.current.classList.remove(css.openRight);
            }, 1000);

            list.current.classList.add(css.closeLeft);
            listNext.current.style.display = 'block';
            listNext.current.classList.add(css.openRight);
        },
        [length]
    );

    const goPrev = useCallback(
        (delta): void => {
            delta = typeof delta === 'number' ? delta : 1;
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setCurrent((current) => {
                    current = current - delta;
                    current = current >= 0 ? current : length - 1;
                    return current;
                });
                list.current.classList.remove(css.closeRight);
                listPrev.current.style.display = 'none';
                listPrev.current.classList.remove(css.openLeft);
            }, 1000);

            list.current.classList.add(css.closeRight);
            listPrev.current.style.display = 'block';
            listPrev.current.classList.add(css.openLeft);
        },
        [length]
    );

    const goN = useCallback(
        (idx) => () => {
            clearTimeout(timerRef.current);
            if (idx > current) {
                goNext(idx - current);
            } else {
                goPrev(current - idx);
            }
        },
        [current, goNext, goPrev]
    );

    const swipeable = useSwipeable({
        delta: 10,
        trackTouch: true,
        trackMouse: true,
        rotationAngle: 0,
        onSwipedLeft: goPrev,
        onSwipedRight: goNext
    });

    return (
        <div className={css.box}>
            <div className={css.list} {...swipeable}>
                <div className={css.item} ref={listPrev} style={hidden}>
                    {children[prev]}
                </div>
                <div className={css.item} ref={list}>
                    {children[current]}
                </div>
                <div className={css.item} ref={listNext} style={hidden}>
                    {children[next]}
                </div>
            </div>
            <div className={css.controls}>
                <Nav className={css.prev} onClick={goPrev} />
                <div className={css.dots}>
                    {range.map((index) => (
                        <div
                            key={index}
                            className={cn(css.dot, {
                                [css.current]: index === current
                            })}
                            onClick={goN(index)}
                        />
                    ))}
                </div>
                <Nav className={css.next} onClick={goNext} />
            </div>
        </div>
    );
};
