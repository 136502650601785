import cn from 'classnames';

import zigzag from 'components/ui/zigzag.webp';

import css from './more.css';

export const More: FC = () => (
    <div className={css.more}>
        <div className={css.container}>
            <img alt='' className={cn(css.side, css.left)} loading='lazy' src={zigzag} />
            <h2 className={css.title} style={{ fontSize: '28px' }}>
                Более&nbsp;<span className={css.highlighted}>500 000</span>
                <br />
                созданных опросов
            </h2>
            <img alt='' className={cn(css.side, css.right)} loading='lazy' src={zigzag} />
        </div>
    </div>
);
