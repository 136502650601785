import lensmasterImg from 'assets/logos/linzmaster.png';
import priumImg from 'assets/logos/n-primum.png';
import rosgImg from 'assets/logos/rosgonki.png';
import rhImg from 'assets/logos/roza-hutor.png';
import skyengImg from 'assets/logos/skyeng.png';
import swageImg from 'assets/logos/swagelok.png';

export const data = [
    {
        name: 'А.В.Титов',
        post: 'Генеральный директор АНО „РОСГОНКИ“',
        src: rosgImg,
        alt: 'АНО „РОСГОНКИ“',
        description: (
            <p>
                Выражается благодарность коллективу ООО „ТЕСТОГРАФ“ за участие в подготовке и проведении FORMULA 1
                ВТБ-ПРИ РОССИИ 2021 и проявленные при этом высокий профессионализм, ответственность и добросовестное
                отношение к решению поставленных задач.
            </p>
        )
    },
    {
        name: 'Никифорова Юлия Владимировна',
        post: 'Начальник отдела аналитики <br /> Департамента стратегии качества',
        src: rhImg,
        alt: 'Роза Хутор',
        description: (
            <>
                <p>
                    Сотрудничество с компанией основано на годовом лицензионном обслуживании. Однако за полгода
                    пользования сервисом, мы успели реализовать еще один проект: подбор необходимой целевой аудитории с
                    помощью ресурсов сервиса testograf. Стоит отметить оперативность в работе подрядчика и полное
                    понимание потребностей своих клиентов.
                </p>
                <p>
                    Со своей стороны, мы готовы рекомендовать компанию как надежного партнера. Надеемся на
                    продолжительное сотрудничество и реализацию совместных амбициозны проектов.
                </p>
            </>
        )
    },
    {
        name: 'Надежда Балаш',
        post: 'Руководитель отдела маркетинговых коммуникаций',
        src: swageImg,
        alt: 'Swagelok',
        description: (
            <>
                <p>
                    Компания Swagelok России выражает благодарность команде ресурса testograf. Наше сотрудничество
                    оставило самые благоприятные впечатления при составлении маркетингового опроса наших клиентов – с
                    сайтом очень удобно работать, все необходимые функции при составлении опроса учтены и интуитивно
                    просты в использовании.
                </p>
                <p>
                    При возникновении вопросов мы всегда получали оперативную помощь от службы поддержки и наиболее
                    эффективное их решение исходя из наших потребностей.
                </p>
            </>
        )
    },
    {
        name: 'Наталия Мандрова',
        post: 'Генеральный директор',
        src: priumImg,
        alt: 'Primum',
        description: (
            <>
                <p>
                    Наше сотрудничество с testograf оставило самые благоприятные впечатления. Мы столкнулись с проблемой
                    корректного сбора ответов на анкету. Команда testograf профессионально подошла к решению
                    поставленной задачи. Все необходимые работы были проведены качественно и оперативно. Собранный
                    массив данных прошел проверку наших аналитиков.
                </p>
                <p>
                    Мы готовы рекомендовать testograf как удобный инструмент для проведения маркетинговых опросов для
                    бизнеса и надежных партнеров по привлечению респондентов.
                </p>
            </>
        )
    },
    {
        name: 'Тимошина Е.А.',
        post: 'Менеджер по маркетинговым проектам, <br />автор проекта «Исследование рынка»',
        src: skyengImg,
        alt: 'Skyeng',
        description: (
            <>
                <p>
                    Мы использовали сервис Тестограф для проведения трех масштабных исследований рынка. В последнем
                    исследовании приняли участие почти 30 000 человек. Самое классное — то, что сервис достаточно
                    совершенен для решения технологических задач, таких как:
                </p>
                <ul>
                    <li>
                        - Передача utm-меток с посадочной страницы исследования на анкету, и далее на финальную
                        страницу.
                    </li>
                    <li> - Возможность задавать разные посадочные страницы в зависимости от ответов пользователей.</li>
                </ul>
                <p>
                    Также хочу отметить оперативность техподдержки — на любые вопросы ребята отвечают оперативно и
                    содержательно. Могу смело рекомендовать сервис для проведения любых опросов и анкет.
                </p>
            </>
        )
    },
    {
        name: 'Гийом Бруве жан Тьерри',
        post: 'Генеральный директор',
        src: lensmasterImg,
        alt: 'Линзмастер',
        description: (
            <>
                <p>
                    Изначально нам был необходим только один опрос и профессиональный отчет с результатами. Все задачи,
                    поставленные перед организацией, были успешно осуществлены. В последствии мы раскрыли для себя
                    гораздо больший функционал testograf и начали использовать его в большем объеме.
                </p>
                <p>
                    Мы надеемся на дальнейшее сотрудничество и рекомендуем testograf как надежную компанию для
                    проведения маркетинговых исследований и опросов.
                </p>
            </>
        )
    }
];
