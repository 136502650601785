import Infrastructure from './img/infrastructure.svg';
import Security from './img/security.svg';
import Support from './img/support.svg';

import css from './advantages.css';

const Label: FC<{
    title: string;
    link?: string;
}> = ({ link, title }) => (
    <h2 className={css.label}>
        {link ? (
            <a href={link}>
                {title}
                <div className={css.box}>
                    <div className={css.icon} />
                </div>
            </a>
        ) : (
            title
        )}
    </h2>
);

export const Advantages: FC = () => (
    <div className={css.advantages}>
        <h2 className={css.title}>Преимущества</h2>
        <ul className={css.list}>
            <li className={css.item}>
                <section className={css.advantage}>
                    <Security className={css.image} />
                    <Label link='/ru/advantages-safety' title='Безопасность' />
                    <div className={css.text}>Лицензионное ПО, соблюдение ФЗ-152.</div>
                </section>
            </li>
            <li className={css.item}>
                <section className={css.advantage}>
                    <Support className={css.image} />
                    <Label title='Поддержка' />
                    <div className={css.text}>Оперативная поддержка по e-mail и телефону.</div>
                </section>
            </li>
            <li className={css.item}>
                <section className={css.advantage}>
                    <Infrastructure className={css.image} />
                    <Label title='Инфраструктура' />
                    <div className={css.text}>Серверы на территории РФ.</div>
                </section>
            </li>
        </ul>
    </div>
);
