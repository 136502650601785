import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { isServer } from '../lib/const';

export const useWindowSize = (delay = 200): [number, number] => {
    const [size, setSize] = useState<[number, number]>([0, 0]);

    useEffect(() => {
        const updateSize = (): void => {
            setSize([isServer ? 0 : window.innerWidth, isServer ? 0 : window.innerHeight]);
        };
        const debouncedHandleResize = debounce(updateSize, delay);
        window.addEventListener('resize', debouncedHandleResize);
        debouncedHandleResize();
        return () => window.removeEventListener('resize', debouncedHandleResize);
    }, [delay]);

    return size;
};
